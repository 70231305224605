import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.blog-carousel');
  const section = document.querySelector('.blog-carousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      const args = {
        centerInsufficientSlides: true,
        direction: 'horizontal',
        breakpoints: {
          slidesPerView: 1,
          spaceBetween: 20,
          640: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 80,
          },
        },
        Mousewheel: true,
        navigation: {
          prevEl: section.querySelector('.swiper-button-prev'),
          nextEl: section.querySelector('.swiper-button-next'),
        },
        pagination: {
          el: carousel.querySelector('.swiper-pagination'),
          clickable: true,
        },
      };

      new Swiper(carousel, args);
    });
  }
});
